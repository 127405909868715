#follow-us-container {
    padding-top: 30px;
    height: 480px;
    vertical-align: top;
    text-align: center;
    background-color: white;
    background-image: url("../../images/tiled_background.gif");
    background-repeat: repeat-x;
    background-position: bottom;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#follow-us-image-holder {
    width: 500px;
    margin-left: 25%;
    margin-right: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.follow-us-container-not-first-img {
    margin-left: 40px;
}

.topoffooter-divider-container {
    display: flex;
    flex-direction: row;
    background-color: #b39765;
    flex-wrap: nowrap;
    justify-content: space-between;
    width: 100%;
    height: 100px;
}

.topoffooter-diamond-divider {
    background-image: url("../../images/whiteblack_diamond.png");
    background-repeat: no-repeat;
    background-position: 50% 50%;
    flex-grow: 5;
    height: 100px;
}

.topoffooter-diamond-divider>hr {
    border: 0.5px solid black;
    margin-top: 50px;
}

.topoffooter-divider-borders {
    width: 49px;
    height: 100px;
    background-color: #00adee;
    background-image: url("../../images/topoffooter_corner.gif");
    background-repeat: no-repeat;
    background-position: 50% 50%;
}

#copyright-span {
    font-size: xx-small;
}

footer {
    background-color: #b39765;
    /* height: 411px; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: nowrap;
    width: 100%;
}

.footer-border {
    width: 53px;
    background-color: #00adee;
}

#footer-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    height: auto;
    align-items: flex-start;
    justify-content: space-evenly;
    margin-bottom: 50px;
}

#footer-logo {
    margin-left: auto;
    margin-right: auto;
}

#footer-logo-holder {
    display: grid;
}

#footer-logo-writing {
    flex-grow: 0;
}

footer ul {
    padding: initial;
}

footer li {
    list-style-type: none;
}

footer a {
    outline: none;
    text-decoration: none;
    size: 22pt;
}

footer a:link,
a:visited,
a:focus {
    color: black;
}

footer a:hover {
    color: white;
}

footer a:active {
    color: #00adee;
}

footer button {
    background-color: #666666;
    border: none;
    color: white;
    padding: 5px;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
}

#footer-follow-us-subscribe img {
    margin-right: 10px;
}