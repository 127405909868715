.banner-container-homepage {
    height: auto;
    background-color: black;
    z-index: -1;
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    max-height: 523px;
}

#banner-quote-holder {
    width: 50%;
    text-align: center;

    /* font-size: 2.5rem; */
    font-size: 2.5vw;
}

#banner-quote {
    color: white;
    margin-bottom: 5vmin;
    font-size: var(--banner-quote-size);
}

#banner-image-holder {
    width: 50%;
    height: 100%;
    align-content: flex-end;
    max-height: 523px;
}