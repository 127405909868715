/* NORMAL TABLET */

/* Content Heading Section */

@media (min-width: 768px) and (max-width: 1024px) {
    section.content-heading {
        width: 100%;
        height: 102.59px;
    }

    section.content-heading h1 {
        margin-top: auto;
        margin-bottom: auto;
        font-size: 20px;
        font-size: calc(15px + 2vw);
    }

    #main-content-container {
        width: 75%;
    }
}


/* End of Section */