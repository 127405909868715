@import "./mobile-definitions.css";
@import "./main-content.css";

@media (max-width: 768px) {
    .mobile-media {

        body {
            width: 100%;
            overflow-x: hidden;
            position: absolute;
        }

        html {
            position: relative;
            overflow-x: hidden;
        }

        /* Header Section (Desktop Header) */
        header {
            display: none;
        }

        /* end */

        /* Header Section (Mobile Header) */
        .smallscreen-header-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-bottom: 30px;
        }

        #smallscreen-logo {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        #smallscreen-logo img {
            width: 40vw;
            max-width: 160px;
        }

        #smallscreen-header-logo {
            width: 80%;
            padding-left: 10%;
        }

        #smallscreen-header-logo-writing {
            width: 80%;
            padding-left: 5%;
            padding-top: 20%;
            position: relative;
        }

        #burger-menu {
            display: flex;
            justify-content: center;
            position: relative;
            padding-top: 5%;
        }

        #burger-menu-diamond {
            position: absolute;
            right: 30%;
            top: 10%;
            margin-left: auto;
            margin-right: auto;
        }

        #burger-button {
            position: relative;
            width: 11vw;
            overflow-x: hidden;
            transform: translateX(37vw);
            margin-top: -30px;
        }

        #smallscreen-menu {
            text-align: center;
            overflow-x: hidden;
            width: 100%;
        }

        #smallscreen-menu ul {
            padding: 0;
            margin: 0;
            background: #f1f1f1;
            font-size: 6.5vmin;
        }

        #smallscreen-menu ul li>ul {
            display: none;
            background: white;
            font-size: 5.5vmin;
        }

        #smallscreen-menu li {
            list-style: none;
            list-style-type: none;
        }

        #smallscreen-menu a {
            outline: none;
            text-decoration: none;
            font-size: 32px;
            margin: 5px;
        }

        #smallscreen-menu a.navbar {
            font-family: "Source Sans Pro";
        }

        #smallscreen-menu a.navbar:link {
            color: #00adee;
        }

        a.sub-navbar.active-link {
            color: var(--gold);
        }

        a.navbar.active-link {
            color: var(--gold);
        }

        #smallscreen-menu a.navbar:hover {
            color: var(--blue);
            font-weight: 900;
        }

        #smallscreen-menu a.navbar:active {
            color: #00adee;
        }

        #smallscreen-menu header #social-media-icon-section,
        .header-corner-diamond {
            display: none;
        }

        #desktop-navbar {
            display: none;
            margin-left: initial;
        }

        .banner-container-homepage {
            display: flex;
            flex-direction: column;
            width: 100%;
            height: 100%;
        }

        #banner-quote-holder {
            padding-top: 5%;
            width: 100%;
            font-size: 1.7vmin;
        }

        #banner-quote {
            color: white;
            text-align: center;
            font-size: 5.5vmin;
        }

        .bannerr {
            max-height: 50vh;
            background-position: center;
            height: 40vh;
            background-size: 90vh;
            background-color: black;
        }

        #banner-image-faith {
            background-image: url('../../images/banner-images/satguru-nanak-jee-janamsakhee.jpg');
            background-repeat: no-repeat;
            background-position-x: center;
        }

        #banner-image-das-satguru {
            background-size: cover;
            height: 40vh;
        }

        #banner-image-sri-guru-granth-sahib-ji {
            background-size: cover;
            height: 40vh;
        }

        #banner-image-amrit-sanchar {
            background-size: cover;
            height: 40vh;
        }

        #banner-image-origins {
            background-size: cover;
            height: 40vh;
        }

        #banner-image-sant-harnam-singh {
            background-size: cover;
            height: 40vh;
        }

        #banner-image-sant-basant-singh {
            background-size: cover;
            height: 40vh;
        }

        #banner-image-sant-gian-singh {
            background-size: cover;
            height: 40vh;
        }

        #banner-image-sant-harbhajan-singh {
            background-size: contain;
            height: 40vh;
        }

        #banner-image-sant-jeet-singh {
            background-size: cover;
            height: 40vh;
        }

        #banner-image-bradford {
            background-size: cover;
            height: 40vh;
        }

        #banner-image-oldbury {
            background-size: cover;
            height: 40vh;
        }

        #banner-image-high-wycombe {
            background-size: cover;
            height: 40vh;
        }

        #banner-image-holder {
            width: 100%;
        }

        .slideshow-container .mySlides {
            width: 100vw;
        }

        .black-diamond-divider {
            width: 100%;
        }

        .blue-border {
            display: none;
        }

        #welcome-container {
            height: 100%;
            padding-top: 65px;
        }

        #welcome-container-left-diamond,
        #welcome-container-right-diamond {
            display: none;
        }

        #welcome-container h1 {
            margin-top: -10vmax;
            font-size: var(--mobile-header-2);
        }

        #welcome-container h2 {
            font-size: var(--mobile-header-3);
        }

        #welcome-container p {
            font-size: var(--mobile-p-1);
            margin: 20px 5px;
        }

        #asthaan-main-container {
            height: unset;
            justify-content: center;
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        #asthaan-holder {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        .diamond {
            overflow-x: hidden;
            overflow-y: hidden;
        }

        article.asthaan {
            padding-bottom: 20px;
        }

        #follow-us-container {
            width: 100%;
        }

        #follow-us-image-holder {
            width: 100%;
        }

        .follow-us-container-not-first-img {
            margin-left: 30px;
        }

        .topoffooter-divider-borders {
            display: none;
        }

        #copyright-span {
            font-size: 15px;
        }

        footer {
            flex-direction: column;
            justify-content: unset;
            height: unset;
        }

        #footer-container {
            flex-direction: column;
            flex-wrap: unset;
            justify-content: unset;
            align-items: center;
            text-align: center;
        }

        .footer-border {
            display: none;
        }

        .mo-break {
            display: unset;
        }
    }

}