/* Mobile Main Content Section */
@media (max-width: 768px) {
    section#main-content {
        width: 100%;
        height: 100%;
    }

    section#main-content-container {
        width: 100%;
    }

    section#main-content-container p {
        width: 95%;
        word-wrap: break-word;
    }

    section#main-content-container blockquote {
        text-align: center;
        padding: 15px 30px;
    }

    section.content-heading h1 {
        text-align: center;
    }

    section.content-heading {
        width: 100%;
        height: 102.59px;
    }

    section.content-heading h1 {
        margin-top: auto;
        margin-bottom: auto;
        font-size: 20px;
        font-size: calc(15px + 2vw);
    }

    #main-content-container {
        margin-left: 15px;
        margin-right: 15px;
    }
}