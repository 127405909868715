@media (min-width:1024px) {

    section#main-content {
        width: 100%;
        height: 100%;
    }

    section#main-content-container {
        padding-top: 15px;
    }

    section#main-content-container p {
        width: 500px;
        word-wrap: break-word;
    }

    section#main-content-container blockquote {
        text-align: center;
        padding: 15px 30px;
        width: 370px;
    }

    #main-content-container {
        width: 50%;
    }
}

.content-heading {
    background-color: #00adee;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #b39765;
    color: white;

    h1 {
        text-align: center;
    }
}

.content-heading-border-diamond {
    background-image: url("../../../images/navbar_corner.gif");
    background-repeat: no-repeat;
    background-position-y: center;
    width: 49px;
}

#main-content {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
}

.blue-border {
    background-color: #00adee;
    min-width: 49px;
}

#main-content-container {
    display: flex;
    align-items: center;
    flex-direction: column;
}

#main-content-container img {
    /* max-width: 60%; */
}

.main-content-img-container {
    display: flex;
    justify-content: center;
}

#content-bottom-tiles {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    background-image: url("../../../images/tiled_background.gif");
    background-repeat: repeat-x;
    background-position: bottom;
    height: 169px;
}

.banner-container-homepage {
    height: auto;
    background-color: black;
    z-index: -1;
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    max-height: 523px;
}

.image-description {
    font-size: 1.6vmin;
    text-align: center;
    padding-bottom: 25px;
}

.bannerr {
    /*height: 523px;*/
    height: 60vh;
    background-color: black;
    z-index: -1;
    flex-direction: row;
    /* background-image: url("../../../images/banner-images/background.jpg"); */
    background-repeat: repeat;
    background-position: center;
    background-size: cover;
}

#banner-image-faith {
    background-image: url("../../../images/banner-images/satguru-nanak-jee-janamsakhee.jpg");
    background-repeat: no-repeat;
    background-position-x: center;
    background-size: cover;
}

#banner-image-das-satguru {
    background-image: url("../../../images/banner-images/das-satguru.jpg");
    background-repeat: no-repeat;
    background-position-x: center;
    background-size: cover;
}

#banner-image-sri-guru-granth-sahib-ji {
    background-image: url("../../../images/banner-images/sri-guru-granth-sahib.jpg");
    background-repeat: no-repeat;
    background-position-x: center;
    background-size: cover;
}

#banner-image-amrit-sanchar {
    background-image: url("../../../images/banner-images/amrit-sanchar.jpg");
    background-repeat: no-repeat;
    background-position-x: center;
    background-size: cover;
}

#banner-image-origins {
    background-image: url("../../../images/banner-images/origins.jpg");
    background-repeat: no-repeat;
    background-position-x: center;
    background-size: cover;
    height: 454px;
    height: 60vh;
}

#banner-image-sant-harnam-singh {
    background-image: url("../../../images/banner-images/sant-harnam-singh-ji.jpg");
    background-repeat: no-repeat;
    background-position-x: center;
    height: 700px;
    height: 60vh;
}

#banner-image-sant-basant-singh {
    background-image: url("../../../images/banner-images/sant-baba-basant-singh-ji.jpg");
    background-repeat: no-repeat;
    background-position-x: center;
    height: 788px;
    height: 60vh;
}

#banner-image-sant-gian-singh {
    background-image: url("../../../images/banner-images/sant-gian-singh-ji2.jpg");
    background-repeat: no-repeat;
    background-position-x: center;
    height: 700px;
    height: 60vh;
}

#banner-image-sant-harbhajan-singh {
    background-image: url("../../../images/banner-images/sant-harbhajan-singh-virakat2.jpg");
    background-repeat: no-repeat;
    background-position-x: center;
    height: 700px;
    height: 60vh;
}

#banner-image-sant-jeet-singh {
    background-image: url("../../../images/banner-images/sant-jeet-singh-ji-sant-gurmeet-singh-ji.jfif");
    background-repeat: no-repeat;
    background-position-x: center;
    background-size: cover;
}

#banner-image-bradford {
    background-image: url("../../../images/banner-images/bradford.jpg");
    background-repeat: no-repeat;
    background-position-x: center;
    background-size: auto;
    height: 60vh;
}

#banner-image-oldbury {
    background-image: url("../../../images/banner-images/oldbury.jpg");
    background-repeat: no-repeat;
    background-position-x: center;
    background-size: contain;
    background-size: auto;
}

#banner-image-high-wycombe {
    background-image: url("../../../images/banner-images/high-wycombe.jpg");
    background-repeat: no-repeat;
    background-position-x: center;
    background-size: contain;
    background-size: auto;
}