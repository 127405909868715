/* Font Sizes */
:root {
    --mobile-header-1: calc(14px + 3.5vmin);
    --mobile-header-2: calc(26px + 1.7vmin);
    --mobile-header-3: calc(12px + 1.7vmin);

    --mobile-banner-1: calc(35px + 1.7vmin);

    --mobile-p-1: calc(15px);

    --blue: #00adee;
    --whitebackground: #f1f1f1;
    --gold: #b39765;

}

/* Font Colours */