#asthaan-main-container a {
    outline: none;
    text-decoration: none;
    font-size: 22px;
    margin: 5px;
}

#asthaan-main-container a {
    font-family: "Source Sans Pro";
}

#asthaan-main-container a:link,
a:visited,
a:focus {
    color: black;
    text-decoration: underline;
}

#asthaan-main-container a:hover {
    color: #00adee;
}

#asthaan-main-container a:active {
    color: black;
    text-decoration: underline;
}

#asthaan-main-container {
    padding-top: 30px;
    height: 750px;
    background-color: #cccccc;
    text-align: center;
}

#asthaan-holder {
    display: flex;
    justify-content: space-around;
}

.asthaan {
    width: 205px;
    background-repeat: no-repeat;
    background-position: top;
}

.diamond {
    margin-top: 50px;
    margin-bottom: 50px;
    width: 205px;
    height: 205px;
    overflow-x: hidden;
    overflow-y: hidden;
    transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    background-color: #00adee;
}

.diamond-text,
#diamond-text-high-wycombe,
#diamond-text-bradford {
    font-family: "Trajan Pro Bold";
    transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    line-height: 205px;
    text-align: center;
    color: white;
    display: table-cell;
    vertical-align: middle;
    width: 205px;
    font-size: 35px;
}

#diamond-text-bradford {
    font-size: 35px;
    line-height: 40px;
    height: 205px;
}

#diamond-text-high-wycombe {
    font-size: 35px;
    line-height: 40px;
    height: 205px;
}