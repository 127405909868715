.image-slide {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
}

.image-slideshow-container {
    position: relative;
    overflow: hidden;
    height: 100%;
    width: 100%;
    min-height: 350px;
    /* min-width: 100%; */
}