#welcome-divider {
    height: 90px;
    background-image: url("../../images/whiteblack_diamond.png");
    background-color: #b39765;
    background-repeat: no-repeat;
    background-position: center;
}

#welcome-divider>hr {
    border: 0.5px solid black;
    margin-top: 45px;
    margin-bottom: 45px;
}

#welcome-container {
    padding-top: 30px;
    background-color: #b39765;
    height: 500px;
    display: flex;
    justify-content: center;
}

#welcome-container-left-diamond {
    min-width: 25%;
    background-image: url("../../images/whiteblack_diamond.png");
    background-color: #b39765;
    background-repeat: no-repeat;
    background-position: center;
}

#welcome-container-right-diamond {
    min-width: 25%;
    background-image: url("../../images/whiteblack_diamond.png");
    background-color: #b39765;
    background-repeat: no-repeat;
    background-position: center;
}

#welcome-section {
    align-self: center;
    text-align: center;
}

#welcome-section>h1 {
    font-size: xx-large;
    color: white;
    margin: 2px 0 2px 0;
}