/* Header */
:root {
    --header-height: 100px;
    --header-min-height: 100px;

    --navbar-margin-left: 1.5vw;
    --navbar-padding-right: 0.5vw;

    --diamond-width: 7vw;
    --diamond-width-max: 50px;

    --logo-height: 110px;
    --logo-width: 110px;
    --logo-position-top: 40px;

    --logo-writing-min-height: 60px;
    --logo-writing-min-width: 125px;
    --logo-writing-margin-top: 15px;
    --logo-writing-margin-right: -1.2vmin;

    --social-media-icon-width: 3.5vw;
    --social-media-icon-min: 25px;
    --social-media-icon-max: 50px;

    --asthaan-holder-bottom-space: 40px;

}

/* Fonts */
:root {
    --banner-quote-size: calc(2.8vmax);

    --blue: #00adee;
    --whitebackground: #f1f1f1;
    --gold: #b39765;
}