@import './tablet-definitions.css';
@import './main-content.css';

/* Ensures the header section doesn"t congest when undex 1000px width */
@media (min-width:950px) and (max-width: 1050px) {
    .logo-writing img {
        width: 15vw;
    }

    header #social-media-icon-section a img {
        width: 4.5vw;
        height: 4.5vw;
    }

    header #social-media-icon-section {
        align-self: center;
        position: static;
        width: 150px;
        overflow-x: hidden;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: nowrap;
        height: var(--header-height);
        min-height: var(--header-min-height);
        width: 100%;
    }

    .header-corner-diamond {
        background-image: url("../../images/navbar_corner.gif");
        background-repeat: no-repeat;
        background-color: #00adee;
        background-position-y: center;
        width: 49px;
        height: var(--header-height);
        min-height: var(--header-min-height);
    }

    #logo-holder {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 300px;
    }

    .logo {
        flex-grow: 0;
        margin-top: var(--logo-position-top);
        margin-right: 2vw;
        padding-left: 2vw;
    }

    .logo img {
        width: var(--logo-width);
        height: var(--logo-height);
    }

    .logo-writing {
        flex: 0;
        margin-top: var(--logo-writing-margin-top);
        margin-left: var(--logo-writing-margin-right);
    }

    .logo-writing img {
        width: 17vw;
        min-width: var(--logo-writing-min-width);
    }

    #smallscreen-logo {
        display: none;
    }

    #burger-menu {
        display: none;
        margin-left: auto;
        margin-right: auto;
        cursor: pointer;
    }

    #smallscreen-menu {
        display: none;
    }

    #desktop-navbar {
        align-self: center;
        display: block;
        margin-left: var(--navbar-margin-left);
        padding-right: var(--navbar-padding-right);
    }

    #desktop-navbar>ul>li {
        float: left;
        position: relative;

    }

    #desktop-navbar ul {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        flex: 0 0 100px;
        padding-inline-start: unset;
    }

    #desktop-navbar ul li {
        list-style-type: none;
        font-family: "Source Sans Pro";
    }

    #desktop-navbar a {
        outline: none;
        text-decoration: none;
        font-size: calc(14px + (26 - 14) * ((100vw - 300px) / (1600 - 300)));
        margin: 5px;
    }

    #desktop-navbar ul li ul {
        position: absolute;
        padding: 0;
        min-width: 300px;
        display: none;
        top: 100%;
        left: 0;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
        z-index: 1;
    }

    #desktop-navbar ul li:hover>ul {
        display: block;
        background: #f1f1f1;
    }

    #desktop-navbar a.navbar:link,
    a.navbar:visited,
    a.navbar:focus {
        color: #00adee;
    }

    #desktop-navbar a.navbar:hover {
        color: black;
    }

    a.sub-navbar:link,
    a.sub-navbar:visited,
    a.sub-navbar:active {
        color: black;
    }

    a.sub-navbar:hover {
        color: #00adee;
    }

    #desktop-navbar a.navbar:active {
        color: #00adee;
    }


    header #social-media-icon-section {
        align-self: center;
        display: flex;
        padding-left: 0.6vw;
        padding-right: calc(10px + 0.2vw);
    }

    header .social-media-logo {
        align-self: center;
    }

    header #social-media-icon-section a img {
        width: var(--social-media-icon-width);
        height: var(--social-media-icon-width);
        min-width: var(--social-media-icon-min);
        min-height: var(--social-media-icon-min);
        max-width: var(--social-media-icon-max);
        max-height: var(--social-media-icon-max);
    }

    .content-heading {
        background-color: #00adee;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        background-color: #b39765;
        color: white;
    }

    .content-heading-border-diamond {
        background-image: url("../../images/navbar_corner.gif");
        background-repeat: no-repeat;
        background-position-y: center;
        width: 49px;
    }

    #main-content {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
    }

    .blue-border {
        background-color: #00adee;
        min-width: 49px;
    }

    #main-content-container {
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 100%;
    }

    #content-bottom-tiles {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        background-image: url("../../images/tiled_background.gif");
        background-repeat: repeat-x;
        background-position: bottom;
        height: 169px;
    }

    .banner-container-homepage {
        height: auto;
        background-color: black;
        z-index: -1;
        display: flex;
        flex-direction: row;
        align-content: center;
        align-items: center;
        max-height: 523px;
        width: auto;
    }

    .bannerr {
        height: 523px;
        background-color: black;
        z-index: -1;
        flex-direction: row;
    }

    #banner-image-faith {
        background-image: url('../../images/banner-images/satguru-nanak-jee-janamsakhee.jpg');
        background-repeat: no-repeat;
        background-position-x: center;
    }

    #banner-image-das-satguru {
        background-image: url('../../images/banner-images/das-satguru.jpg');
        background-repeat: no-repeat;
        background-position-x: center;
    }

    #banner-image-sri-guru-granth-sahib-ji {
        background-image: url('../../images/banner-images/sri-guru-granth-sahib.jpg');
        background-repeat: no-repeat;
        background-position-x: center;
    }

    #banner-image-amrit-sanchar {
        background-image: url('../../images/banner-images/amrit-sanchar.jpg');
        background-repeat: no-repeat;
        background-position-x: center;
    }

    #banner-image-origins {
        background-image: url('../../images/banner-images/origins.jpg');
        background-repeat: no-repeat;
        background-position-x: center;
        height: 454px;
    }

    #banner-image-sant-harnam-singh {
        background-image: url('../../images/banner-images/sant-harnam-singh-ji.jpg');
        background-repeat: no-repeat;
        background-position-x: center;
        height: 700px;
    }

    #banner-image-sant-basant-singh {
        background-image: url('../../images/banner-images/sant-baba-basant-singh-ji.jpg');
        background-repeat: no-repeat;
        background-position-x: center;
        height: 788px;
    }

    #banner-image-sant-gian-singh {
        background-image: url('../../images/banner-images/sant-gian-singh-ji.jpg');
        background-repeat: no-repeat;
        background-position-x: center;
        height: 700px;
    }

    #banner-image-sant-harbhajan-singh {
        background-image: url('../../images/banner-images/sant-harbhajan-singh-virakat.jpg');
        background-repeat: no-repeat;
        background-position-x: center;
        height: 700px;
    }

    #banner-image-sant-jeet-singh {
        background-image: url('../../images/banner-images/sant-jeet-singh-ji-sant-gurmeet-singh-ji.jfif');
        background-repeat: no-repeat;
        background-position-x: center;
    }

    #banner-image-bradford {
        background-image: url('../../images/banner-images/bradford.jpg');
        background-repeat: no-repeat;
        background-position-x: center;
    }

    #banner-image-oldbury {
        background-image: url('../../images/banner-images/oldbury.jpg');
        background-repeat: no-repeat;
        background-position-x: center;
    }

    #banner-image-high-wycombe {
        background-image: url('../../images/banner-images/high-wycombe.jpg');
        background-repeat: no-repeat;
        background-position-x: center;
    }

    #banner-quote-holder {
        width: 50%;
        text-align: center;
        font-size: 2.5vw;
        margin-top: 5vh;
    }

    #banner-quote {
        color: white;
        margin-bottom: 5vmin;
        font-size: var(--banner-quote-size);
    }

    #banner-image-holder {
        width: 50%;
        height: 100%;
        align-content: flex-end;
        max-height: 523px;
    }

    #welcome-divider {
        height: 90px;
        background-image: url("../../images/whiteblack_diamond.png");
        background-color: #b39765;
        background-repeat: no-repeat;
        background-position: center;
    }

    #welcome-divider>hr {
        border: 0.5px solid black;
        margin-top: 45px;
    }

    #welcome-container {
        background-color: #b39765;
        height: 100%;
        display: flex;
        justify-content: center;
    }

    #welcome-container-left-diamond {
        min-width: 25%;
        background-image: url("../../images/whiteblack_diamond.png");
        background-color: #b39765;
        background-repeat: no-repeat;
        background-position: center;
    }

    #welcome-container-right-diamond {
        min-width: 25%;
        background-image: url("../../images/whiteblack_diamond.png");
        background-color: #b39765;
        background-repeat: no-repeat;
        background-position: center;
    }

    #welcome-section {
        align-self: center;
        text-align: center;
    }

    #welcome-section>h1 {
        font-size: xx-large;
        color: white;
        margin: 2px 0 2px 0;
    }

    #asthaan-main-container a {
        outline: none;
        text-decoration: none;
        font-size: 22px;
        margin: 5px;
    }

    #asthaan-main-container a {
        font-family: "Source Sans Pro";
    }

    #asthaan-main-container a:link,
    a:visited,
    a:focus {
        color: black;
        text-decoration: underline;
    }

    #asthaan-main-container a:hover {
        color: #00adee;
    }

    #asthaan-main-container a:active {
        color: black;
        text-decoration: underline;
    }

    #asthaan-main-container {
        height: 60vh;
        background-color: #cccccc;
        text-align: center;
        height: unset;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }

    #asthaan-holder {
        display: flex;
        justify-content: space-around;
        padding-bottom: var(--asthaan-holder-bottom-space);
    }

    .asthaan {
        width: 205px;
        background-repeat: no-repeat;
        background-position: top;
    }

    .diamond {
        margin-top: 50px;
        margin-bottom: 50px;
        margin-left: 3vw;
        transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        height: 150px;
        width: 150px;
        background-color: #00adee;
        overflow-x: hidden;
        overflow-y: hidden;
    }

    .diamond-text,
    #diamond-text-high-wycombe,
    #diamond-text-bradford {
        font-family: "Trajan Pro Bold";
        transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
        line-height: 150px;
        text-align: center;
        color: white;
        display: table-cell;
        vertical-align: middle;
        width: 205px;
        font-size: 22px;
    }

    #diamond-text-bradford {
        font-size: 22px;
        line-height: 40px;
        height: 150px;
        position: relative;
        left: 1%;
    }

    #diamond-text-high-wycombe {
        font-size: 22px;
        line-height: 40px;
        height: 150px;
        left: 1%;
        position: relative;
    }

    #follow-us-container {
        height: 480px;
        vertical-align: top;
        text-align: center;
        background-color: white;
        background-image: url("../../images/tiled_background.gif");
        background-repeat: repeat-x;
        background-position: bottom;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    #follow-us-image-holder {
        width: 500px;
        margin-left: 25%;
        margin-right: 25%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .follow-us-container-not-first-img {
        margin-left: 40px;
    }

    .topoffooter-divider-container {
        display: flex;
        flex-direction: row;
        background-color: #b39765;
        flex-wrap: nowrap;
        justify-content: space-between;
        width: 100%;
        height: 100px;
    }

    .topoffooter-diamond-divider {
        background-image: url("../../images/whiteblack_diamond.png");
        background-repeat: no-repeat;
        background-position: 50% 50%;
        flex-grow: 5;
        height: 100px;
    }

    .topoffooter-diamond-divider>hr {
        border: 0.5px solid black;
        margin-top: 50px;
    }

    .topoffooter-divider-borders {
        width: 49px;
        height: 100px;
        background-color: #00adee;
        background-image: url("../../images/topoffooter_corner.gif");
        background-repeat: no-repeat;
        background-position: 50% 50%;
    }

    #copyright-span {
        font-size: xx-small;
    }

    footer {
        background-color: #b39765;
        /* height: 411px; */
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: nowrap;
        width: 100%;

    }

    .footer-border {
        width: 53px;
        background-color: #00adee;
    }

    @media (min-width:768px) and (max-width:1024px) {
        #footer-container {
            display: grid;
            grid-template-columns: auto auto auto;
        }

        #footer-logo-holder {
            display: grid;
            grid-row-start: 1;
            grid-row-end: 1;
        }

        #contact-us-holder {
            text-align: center;
            padding: 0;
            text-align: center;
        }

        #footer-explore-links {
            text-align: center;
        }

        #footer-follow-us-subscribe {
            margin-top: -50px;
            grid-column-start: 3;
            grid-column-end: 4;
        }

        #footer-follow-us-subscribe a img {
            margin-left: 2.5vw;
            text-align: left;
        }
    }

    #footer-logo-holder {
        display: grid;
    }

    #footer-logo {
        align-items: center;
        margin-left: auto;
        margin-right: auto;
    }

    #footer-logo-writing {
        width: 120px;
        margin-left: auto;
        margin-right: auto;
    }

    footer ul {
        padding: initial;
    }

    footer li {
        list-style-type: none;
    }

    footer a {
        outline: none;
        text-decoration: none;
        size: 22pt;
    }

    footer a:link,
    a:visited,
    a:focus {
        color: black;
    }

    footer a:hover {
        color: white;
    }

    footer a:active {
        color: #00adee;
    }

    footer button {
        background-color: #666666;
        border: none;
        color: white;
        padding: 5px;
        text-align: center;
        text-decoration: none;
        cursor: pointer;
    }

    #footer-follow-us-subscribe img {
        margin-right: 10px;
        margin: 0px;
    }

    #footer-follow-us-subscribe h3 {
        text-align: center;
    }

    .mo-break {
        display: none;
    }

}