@import "./main-content/main-content.css";
@import "./desktop-definitions.css";

h1 {
    font-size: 35px;
}

header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: nowrap;
    width: 100%;
    height: 130px;
    background-color: white;
}

.header-corner-diamond {
    background-image: url("../../images/navbar_corner.gif");
    background-repeat: no-repeat;
    background-color: #00adee;
    background-position-y: center;
    width: 49px;
    height: 130px;
}

#logo-holder {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 300px;
}

.logo {
    flex-grow: 0;
    margin-top: 29px;
}

.logo-writing {
    flex: 0;
    margin-top: 29px;
}

#desktop-navbar {
    align-self: center;
}

#desktop-navbar>ul>li {
    float: left;
    position: relative;
}

#desktop-navbar ul {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex: 0 0 100px;
    padding-inline-start: unset;
}

#desktop-navbar ul li {
    list-style-type: none;
    font-family: "Source Sans Pro";
}

#desktop-navbar a {
    outline: none;
    text-decoration: none;
    font-size: 1.5vw;
    margin: 0 10px;
}

#desktop-navbar ul li ul {
    position: absolute;
    padding: 0;
    min-width: 300px;
    display: none;
    top: 100%;
    left: 0;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

.menu-diamond {
    padding-right: 8px;
    width: 0.8vw;
}

#desktop-navbar ul li:hover>ul {
    display: block;
    background: #f1f1f1;
}

#desktop-navbar a.navbar:link,
a.navbar:visited,
a.navbar:focus {
    color: #00adee;
}

#desktop-navbar a.navbar:hover {
    color: black;
}

a.sub-navbar:link,
a.sub-navbar:visited,
a.sub-navbar:active {
    color: black;
}

a.sub-navbar:hover {
    color: #00adee;
}

#desktop-navbar a.navbar:active {
    color: #00adee;
}

header #social-media-icon-section {
    align-self: center;
    display: flex;
}

header .social-media-logo {
    align-self: center;
}

.header-logo-writing {
    img {
        padding-top: 25px;
    }
}

#smallscreen-logo {
    display: none;
}

#burger-menu {
    display: none;
    cursor: pointer;
    margin-top: 10px;
    margin-bottom: 10px;
}

#smallscreen-menu {
    display: none;
}

.content-heading {
    background-color: #00adee;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #b39765;
    color: white;
}

.content-heading-border-diamond {
    background-image: url("../../images/navbar_corner.gif");
    background-repeat: no-repeat;
    background-position-y: center;
    width: 49px;
}

#main-content {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
}

.blue-border {
    background-color: #00adee;
    min-width: 49px;
}

#main-content-container {
    display: flex;
    align-items: center;
    flex-direction: column;
}

#main-content-container img {
    max-width: 60%;
}

#content-bottom-tiles {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    background-image: url("../../images/tiled_background.gif");
    background-image: url("../../images/navbar_corner.gif");
    background-repeat: repeat-x;
    background-position: bottom;
    height: 169px;
}

.banner-container-homepage {
    height: auto;
    background-color: black;
    z-index: -1;
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    max-height: 523px;
}

.image-description {
    font-size: 1.6vmin;
    text-align: center;
    padding-bottom: 25px;
}

.bannerr {
    height: 60vh;
    background-color: black;
    z-index: -1;
    flex-direction: row;
    /* // background-image: url("../../images/banner-images/background.jpg"); */
    background-repeat: repeat;
    background-position: center;
    background-size: cover;
}

#banner-image-faith {
    background-image: url("../../images/banner-images/satguru-nanak-jee-janamsakhee.jpg");
    background-repeat: no-repeat;
    background-position-x: center;
    background-size: cover;
}

#banner-image-das-satguru {
    background-image: url("../../images/banner-images/das-satguru.jpg");
    background-repeat: no-repeat;
    background-position-x: center;
    background-size: cover;
}

#banner-image-sri-guru-granth-sahib-ji {
    background-image: url("../../images/banner-images/sri-guru-granth-sahib.jpg");
    background-repeat: no-repeat;
    background-position-x: center;
    background-size: cover;
}

#banner-image-amrit-sanchar {
    background-image: url("../../images/banner-images/amrit-sanchar.jpg");
    background-repeat: no-repeat;
    background-position-x: center;
    background-size: cover;
}

#banner-image-origins {
    background-image: url("../../images/banner-images/origins.jpg");
    background-repeat: no-repeat;
    background-position-x: center;
    background-size: cover;
    height: 454px;
    height: 60vh;
}

#banner-image-sant-harnam-singh {
    background-image: url("../../images/banner-images/sant-harnam-singh-ji.jpg");
    background-repeat: no-repeat;
    background-position-x: center;
    height: 700px;
    height: 60vh;
}

#banner-image-sant-basant-singh {
    background-image: url("../../images/banner-images/sant-baba-basant-singh-ji.jpg");
    background-repeat: no-repeat;
    background-position-x: center;
    height: 788px;
    height: 60vh;
}

#banner-image-sant-gian-singh {
    background-image: url("../../images/banner-images/sant-gian-singh-ji2.jpg");
    background-repeat: no-repeat;
    background-position-x: center;
    height: 700px;
    height: 60vh;
}

#banner-image-sant-harbhajan-singh {
    background-image: url("../../images/banner-images/sant-harbhajan-singh-virakat2.jpg");
    background-repeat: no-repeat;
    background-position-x: center;
    height: 700px;
    height: 60vh;
}

#banner-image-sant-jeet-singh {
    background-image: url("../../images/banner-images/sant-jeet-singh-ji-sant-gurmeet-singh-ji.jfif");
    background-repeat: no-repeat;
    background-position-x: center;
    background-size: cover;
}

#banner-image-bradford {
    background-image: url("../../images/banner-images/bradford.jpg");
    background-repeat: no-repeat;
    background-position-x: center;
    background-size: auto;
    height: 60vh;
}

#banner-image-oldbury {
    background-image: url("../../images/banner-images/oldbury.jpg");
    background-repeat: no-repeat;
    background-position-x: center;
    background-size: contain;
    background-size: auto;
}

#banner-image-high-wycombe {
    background-image: url("../../images/banner-images/high-wycombe.jpg");
    background-repeat: no-repeat;
    background-position-x: center;
    background-size: contain;
    background-size: auto;
}

#banner-quote-holder {
    width: 50%;
    text-align: center;

    /* font-size: 2.5rem; */
    font-size: 2.5vw;
}

#banner-quote {
    color: white;
    margin-bottom: 5vmin;
    font-size: var(--banner-quote-size);
}

#banner-image-holder {
    width: 50%;
    height: 100%;
    align-content: flex-end;
    max-height: 523px;
}

#welcome-divider {
    height: 90px;
    background-image: url("../../images/whiteblack_diamond.png");
    background-color: #b39765;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    position: absolute;
}

#welcome-divider>hr {
    border: 0.5px solid black;
    margin-top: 45px;
    margin-bottom: 45px;
}

#welcome-container {
    background-color: #b39765;
    height: 500px;
    display: flex;
    justify-content: center;
}

#welcome-container-left-diamond {
    min-width: 25%;
    background-image: url("../../images/whiteblack_diamond.png");
    background-color: #b39765;
    background-repeat: no-repeat;
    background-position: center;
}

#welcome-container-right-diamond {
    min-width: 25%;
    background-image: url("../../images/whiteblack_diamond.png");
    background-color: #b39765;
    background-repeat: no-repeat;
    background-position: center;
}

#welcome-section {
    align-self: center;
    text-align: center;
}

#welcome-section>h1 {
    font-size: xx-large;
    color: white;
    margin: 2px 0 2px 0;
}

#asthaan-main-container a {
    outline: none;
    text-decoration: none;
    font-size: 22px;
    margin: 5px;
}

#asthaan-main-container a {
    font-family: "Source Sans Pro";
}

#asthaan-main-container a:link,
a:visited,
a:focus {
    color: black;
    text-decoration: underline;
}

#asthaan-main-container a:hover {
    color: #00adee;
}

#asthaan-main-container a:active {
    color: black;
    text-decoration: underline;
}

#asthaan-main-container {
    height: 750px;
    background-color: #cccccc;
    text-align: center;
}

#asthaan-holder {
    display: flex;
    justify-content: space-around;
}

.asthaan {
    width: 205px;
    background-repeat: no-repeat;
    background-position: top;
}

.diamond {
    margin-top: 50px;
    margin-bottom: 50px;
    width: 205px;
    height: 205px;
    overflow-x: hidden;
    overflow-y: hidden;
    transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    background-color: #00adee;
}

.diamond-text,
#diamond-text-high-wycombe,
#diamond-text-bradford {
    font-family: "Trajan Pro Bold";
    transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    line-height: 205px;
    text-align: center;
    color: white;
    display: table-cell;
    vertical-align: middle;
    width: 205px;
    font-size: 35px;
}

#diamond-text-bradford {
    font-size: 35px;
    line-height: 40px;
    height: 205px;
}

#diamond-text-high-wycombe {
    font-size: 35px;
    line-height: 40px;
    height: 205px;
}

#follow-us-container {
    height: 480px;
    vertical-align: top;
    text-align: center;
    background-color: white;
    background-image: url("../../images/tiled_background.gif");
    background-repeat: repeat-x;
    background-position: bottom;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#follow-us-image-holder {
    width: 500px;
    margin-left: 25%;
    margin-right: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.follow-us-container-not-first-img {
    margin-left: 40px;
}

.topoffooter-divider-container {
    display: flex;
    flex-direction: row;
    background-color: #b39765;
    flex-wrap: nowrap;
    justify-content: space-between;
    width: 100%;
    height: 100px;
}

.topoffooter-diamond-divider {
    background-image: url("../../images/whiteblack_diamond.png");
    background-repeat: no-repeat;
    background-position: 50% 50%;
    flex-grow: 5;
    height: 100px;
}

.topoffooter-diamond-divider>hr {
    border: 0.5px solid black;
    margin-top: 50px;
}

.topoffooter-divider-borders {
    width: 49px;
    height: 100px;
    background-color: #00adee;
    background-image: url("../../images/topoffooter_corner.gif");
    background-repeat: no-repeat;
    background-position: 50% 50%;
}

#copyright-span {
    font-size: xx-small;
}

footer {
    background-color: #b39765;
    /* height: 411px; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: nowrap;
    width: 100%;
}

.footer-border {
    width: 53px;
    background-color: #00adee;
}

#footer-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    height: auto;
    align-items: flex-start;
    justify-content: space-evenly;
    margin-bottom: 50px;
}

#footer-logo {
    margin-left: auto;
    margin-right: auto;
}

#footer-logo-holder {
    display: grid;
}

#footer-logo-writing {
    flex-grow: 0;
}

footer ul {
    padding: initial;
}

footer li {
    list-style-type: none;
}

footer a {
    outline: none;
    text-decoration: none;
    size: 22pt;
}

footer a:link,
a:visited,
a:focus {
    color: black;
}

footer a:hover {
    color: white;
}

footer a:active {
    color: #00adee;
}

footer button {
    background-color: #666666;
    border: none;
    color: white;
    padding: 5px;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
}

#footer-follow-us-subscribe img {
    margin-right: 10px;
}

.mo-break {
    display: none;
}