/* Provide sufficient contrast against white background */
@import "./global/styles/index.css";

@font-face {
    font-family: "Trajan Pro Bold";
    src: url("./global/fonts/Trajan\ Pro\ Bold.ttf");
}

@font-face {
    font-family: "Sans Pro Regular";
    src: url("./global/fonts/SourceSansPro-Regular.otf");
}

@font-face {
    font-family: "Source Sans Pro";
    src: url("./global/fonts/SourceSansPro-Regular.otf");
}

body {
    font-family: "Sans Pro Regular";
    display: flex;
    flex-direction: column;
    margin: 0px;
    justify-content: center;
}

h1 {
    font-family: "Trajan Pro Bold";
    font-size: 35px;
}

h2 {
    font-family: "Sans Pro Regular";
    font-style: italic;
}